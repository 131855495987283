<template>
  <div v-if="topicId && teachers.length" class="teachers-block bg-white multi-teacher" id="seminar-teacher-sp">
    <p class="teachers-block-title font-weight-bold color-darkgrey">
      Thông tin giáo viên
    </p>
    <div class="teachers d-flex align-items-start flex-wrap flex-lg-nowrap flex-column flex-lg-row">
      <div class="each-teacher media-body" v-for="(teacher, ind) in teachers" :key="ind">
        <div class="short-hand d-flex">
          <ImageCustom type="avatar" :src="teacher.avatar" alt="" class="teacher-avatar rounded-circle" />
          <div class="short-hand-detail">
            <p class="teacher-name mb-2 color-darkgrey">
              {{ teacher.name }}
            </p>
            <p class="teacher-pos d-flex align-items-center iconic mb-2 color-darkgrey" v-if="teacher.level">
              <img src="~/assets/icons/library/bag.svg" width="16" alt=""> {{ teacher.level }}
            </p>
            <p class="teacher-address d-flex align-items-center iconic mb-2 color-darkgrey" v-if="teacher.address">
              <img src="~/assets/icons/library/location.svg" width="16" alt=""> {{ teacher.address }}
            </p>
            <p class="teacher-job-counter d-flex align-items-center iconic mb-2 color-darkgrey" v-if="teacher.number_topic">
              <img src="~/assets/icons/library/play.svg" width="16" alt=""> {{ teacher.number_topic }} khóa học
            </p>
            <!-- <p class="teacher-avg-rate iconic mb-2 color-darkgrey">
              <img src="~/assets/icons/library/yellow-star.svg" width="1rem" alt=""> 4.7
            </p> -->
          </div>
        </div>
        <div class="action" hidden>
          <button type="button" class="btn-fk join-btn bg-color-darkblue d-flex align-items-center ml-auto"><img src="~/assets/icons/library/white-add-user.svg" alt="" />Theo dõi</button>
        </div>
        <div class="teacher-desc">
          <div class="">
            <div class="each-desc text-justify mb-2 text-break" v-html="teacher.description"></div>
            <div class="each-desc text-justify" v-if="teacher.number_topic">
              <div class="font-weight-bold mb-2">Các Khóa học đang giảng dạy:</div>
              <div class="py-1 d-flex align-items-baseline text-primary" v-for="(topic, idx) in teacher.topics" :key="idx">
                <nuxt-link :to="'/khoa-hoc/'+topic.slug" ><img src="~/assets/icons/library/black-play-btn.svg" class="mr-1" alt=""> {{topic.name}}</nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
const teacherResource = new Resource('library/topic/teacher')
export default {
  props: {
    topicId: {
      type: [String, Number],
      default: '',
      required: true
    }
  },
  data () {
    return {
      teachers: []
    }
  },
  created () {
    this.loadTeacher()
  },
  methods: {
    async loadTeacher () {
      if (!this.topicId) { return }
      const { data } = await teacherResource.list({ topic_id: this.topicId })
      this.teachers = data
      this.$emit('loaded', data.length > 0)
    }
  }
}
</script>

<style lang="scss" scoped>
  .teachers-block {
    box-shadow: 0px 2px .25rem rgba(174, 174, 174, 0.34);
    border-radius: .25rem;
    padding: 1.875rem 1.5rem;
    margin-bottom: 2rem;
    @media (max-width: 1366px) {}
    @media (max-width: 1200px) {}
    @media (max-width: 992px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {
      padding: 1.125rem 1rem;
      margin-bottom: 1rem;
    }
    &-title {
      line-height: 1.1;
      font-size: 1.25rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }
    .each-teacher {
      .short-hand {
        gap: 1.25rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          gap: .75rem;
        }
        .teacher-avatar {
          width: 8.4rem;
          height: 8.4rem;
          @media (max-width: 1366px) {
            width: 6rem;
            height: 6rem;
          }
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {
            width: 5rem;
            height: 5rem;
          }
        }
      }
      .short-hand-detail {
        .teacher {
          &-name {
            font-weight: 600;
            font-size: 1.125rem;
            @media (max-width: 1366px) {}
            @media (max-width: 1200px) {}
            @media (max-width: 992px) {}
            @media (max-width: 768px) {}
            @media (max-width: 576px) {
              font-size: 1rem;
            }
          }
          &-pos,
          &-address,
          &-job-counter,
          &-avg-rate {
            line-height: 1;
            gap: .625rem;
            font-size: 1.25rem;
            @media (max-width: 1366px) {
              font-size: 1rem;
            }
            @media (max-width: 1200px) {}
            @media (max-width: 992px) {}
            @media (max-width: 768px) {}
            @media (max-width: 576px) {
              gap: .5rem;
              font-size: 1rem;
            }
          }
          &-avg-rate {
            font-weight: 600;
          }
        }
      }
      .action {
        .btn-fk {
          gap: 1px;
          background: #4086ff;
          border-radius: 1.875rem;
          color: #ffffff;
          width: fit-content;
          padding: .3rem .7rem;
          font-size: .8rem;
          line-height: 1;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {}
        }
      }
      .teacher-desc {
        padding-top: 2.25rem;
        @media (max-width: 1366px) {
          padding-top: 1.5rem;
        }
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          padding-top: 1rem;
        }
        .each-desc {
          font-size: 1rem;
          line-height: 1.25;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {}
        }
      }
    }
    &.multi-teacher {
      .teachers {
        gap: 1.5rem;
        @media (max-width: 1366px) {
          gap: 1rem;
        }
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          gap: 1rem;
        }
      }
      .each-teacher {
        border: 1px solid #e3e3e3;
        border-radius: .25rem;
        padding: 1rem;
        @media (max-width: 1366px) {
          padding: .75rem;
        }
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          padding: .5rem;
        }
      }
    }
  }
</style>
<style lang="scss">
  .teachers-block{
    .teachers{
      ul{
        padding-left: revert;
      }
    }
  }
</style>
