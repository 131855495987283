<template>
  <nuxt-link v-if="item" :to="'/khoa-hoc/' + item.slug" >
    <div class="card-prod pointer" :class="{'prod-followed': item.is_followed, 'card-detail-view': !viewList}" @click="checkBeforeRedirect">
      <div v-if="viewList" class="card position-relative overflow-hidden">
        <img v-if="!item.is_followed" src="~/assets/icons/library/white-circle-plus.svg" title="Theo dõi" class="follow-icon position-absolute pointer" alt="" @click.stop="handleSave"/>
        <img v-else src="~/assets/icons/library/black-circle-plus.svg" title="Bỏ theo dõi" class="follow-icon position-absolute pointer" alt="" @click.stop="handleSave"/>
        <ImageNuxt class="card-img-top" :src="item.avatar || item.thumbnail || 'lesson'" alt="Card image cap" preset="topic" />
        <div class="card-body">
          <p class="prod-title font-weight-bold limit-line-2 text-wrap">{{ item.name }}</p>
          <div class="prod-users d-flex flex-nowrap align-items-center text-truncate" v-if="item.teachers && item.teachers.length">
            <div class="user flex-shrink-0 d-flex align-items-center color-slightgrey2" v-for="(teacher, ind) in item.teachers" :key="ind">
              <ImageNuxt :src="teacher.avatar || 'avatar'" alt="avatar" class="user-avatar rounded-circle" :width="24" :height="24" preset="topic" />
              <!-- <ImageCustom :src="teacher.avatar" alt="avatar" class="user-avatar rounded-circle" :width="24" :height="24" /> -->
              {{ teacher.fullName || teacher.name }}
            </div>
          </div>
          <div class="prod-video-counter d-flex align-items-center color-slightgrey2">
            <img src="~/assets/icons/library/play-btn-icon.svg" alt="">
            {{ item.number_video }} video
          </div>
          <div class="prod-video-live-stream-counter d-flex align-items-center mb-0 color-slightgrey2" v-if="item.number_livestream">
            <img src="~/assets/icons/library/live-stream-icon.svg" alt="">
            {{ item.number_livestream }} buổi Livestream
          </div>
        </div>
      </div>
      <div v-else class="card position-relative overflow-hidden d-flex flex-row flex-nowrap">
        <div class="preview-image position-relative">
          <img v-if="!item.is_followed" src="~/assets/icons/library/white-circle-plus.svg" title="Theo dõi" class="follow-icon position-absolute pointer" alt="" @click.stop="handleSave"/>
          <img v-else src="~/assets/icons/library/black-circle-plus.svg" title="Bỏ theo dõi" class="follow-icon position-absolute pointer" alt="" @click.stop="handleSave"/>
          <!-- <img class="card-img-top w-100 h-100" :src="item.avatar || item.thumbnail" alt="Card image cap"> -->
          <ImageNuxt class="card-img-top w-100 h-100" :src="item.avatar || item.thumbnail || 'lesson'" alt="Card image cap" preset="topic" />
        </div>
        <div class="card-body">
          <p class="prod-title font-weight-bold limit-line-2">{{ item.name }}</p>
          <div class="prod-users d-flex flex-wrap align-items-center" v-if="item.teachers && item.teachers.length">
            <div class="user d-flex align-items-center color-slightgrey2" v-for="(teacher, ind) in item.teachers" :key="ind">
              <!-- <ImageCustom :src="teacher.avatar" alt="avatar" class="user-avatar rounded-circle" width="24" height="24" type="avatar" /> -->
              <ImageNuxt :src="teacher.avatar || 'avatar'" alt="avatar" class="user-avatar rounded-circle" :width="24" :height="24" preset="topic" />
              {{ teacher.fullName || teacher.name }}
            </div>
          </div>
          <div class="prod-video-counter d-flex align-items-center color-slightgrey2">
            <img src="~/assets/icons/library/play-btn-icon.svg" alt="">
            {{ item.number_video }} video
          </div>
          <div class="prod-video-live-stream-counter d-flex align-items-center mb-0 color-slightgrey2" v-if="item.number_livestream">
            <img src="~/assets/icons/library/live-stream-icon.svg" alt="">
            {{ item.number_livestream }} buổi Livestream
          </div>
        </div>
        <template v-if="myLibrary">
          <!-- <span v-if="item.is_joined" class="btn-join pointer ml-auto align-self-center" @click.stop="handleJoinClass">Vào học</span> -->
          <img src="~/assets/icons/library/trash-bin.svg" alt="" class="mr-3" @click.stop="type === 'learning' ? handleDelete() : handleSave()">
        </template>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import Resource from '~/common/api/resource'
import ImageNuxt from '~/components/Image/nuxt'
const followResource = new Resource('library/topic/follow')
const unFollowResource = new Resource('library/topic/unFollow')
const deleteTopicResource = new Resource('library/topic/delete-learning')

export default {
  components: {
    ImageNuxt
  },
  props: {
    viewList: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default: null
    },
    action: {
      type: Boolean,
      default: true
    },
    myLibrary: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'following'
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    handleSave () {
      if (this.item.is_followed) {
        unFollowResource.store({ topic_id: this.item.id })
          .then(() => {
            // eslint-disable-next-line vue/no-mutating-props
            this.item.is_followed = 0
            this.$emit('unfollow')
          })
          .catch(() => {})
      } else {
        followResource.store({ topic_id: this.item.id })
          .then(() => {
            // eslint-disable-next-line vue/no-mutating-props
            this.item.is_followed = 1
          })
          .catch(() => {})
      }
    },
    checkBeforeRedirect () {
      // if (!this.user) {
      //   this.$bvModal.show('login')
      //   return
      // }
      // const item = { ...this.item }
      // item.is_free = 1
      // this.$helper.checkUserBuySub(this, item, '/thu-vien/khoa-hoc/' + item.slug)
    },
    // handleJoinClass () {
    //   console.log('handleJoinClass')
    // },
    handleDelete () {
      deleteTopicResource.destroy(this.item.id)
        .then(() => {
          this.$emit('delete')
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-prod {
    .card {
      border-radius: .5rem;
      box-shadow: 0px 4px 1rem rgba(0, 0, 0, 0.1);
      border-color: #eaeaea;
      &:before {
        content: '';
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0) 100%);
        border-radius: .5rem .5rem 0px 0px;
        z-index: 2;
        height: 3.4rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          height: 2.5rem;
        }
      }
      .card-img-top {
        aspect-ratio: 327 / 193;
        // object-fit: cover;
      }
      .follow-icon {
        z-index: 3;
        top: .75rem;
        right: .75rem;
      }
      .card-body {
        padding: .75rem;
        min-height: 10.5rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          min-height: 5rem;
        }
        .prod-title {
          font-size: 1rem;
          line-height: 1.4;
          gap: .5rem;
          margin-bottom: .75rem;
          min-height: 3rem;
          @media (max-width: 1366px) {
            margin-bottom: .5rem;
            min-height: 3rem;
          }
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {
            margin-bottom: .5rem;
            min-height: 1rem;
          }
        }
        .prod-users {
          gap: .5rem;
          margin-bottom: .625rem;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {}
          .user {
            gap: .375rem;
            font-size: .95rem;
            line-height: 1.2;
            color: #777777;
            @media (max-width: 1366px) {}
            @media (max-width: 1200px) {}
            @media (max-width: 992px) {}
            @media (max-width: 768px) {}
            @media (max-width: 576px) {}
          }
        }
        .prod-video-live-stream-counter,
        .prod-video-counter {
          margin-bottom: .625rem;
          gap: .375rem;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {}
        }
      }
      .btn-join{
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.3;
        color: #449e2d;
        width: 5rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
      }
    }
    &.card-detail-view {
			.card {
				&:before {
					content: unset;
				}
				.preview-image {
					border-radius: 3px;
					min-width: 16.8rem;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {
            min-width: 10rem;
          }
					&::before {
						content: '';
						width: 100%;
						top: 0;
						left: 0;
						position: absolute;
						background: linear-gradient(180deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0) 100%);
						border-radius: .5rem .5rem 0px 0px;
						z-index: 2;
						height: 3.4rem;
            @media (max-width: 1366px) {}
            @media (max-width: 1200px) {}
            @media (max-width: 992px) {}
            @media (max-width: 768px) {}
            @media (max-width: 576px) {
              height: 2.5rem;
            }
					}
          .card-img-top {
            object-fit: cover;
            border-radius: .5rem;
            max-width: 16.8rem;
            @media (max-width: 1366px) {}
            @media (max-width: 1200px) {}
            @media (max-width: 992px) {}
            @media (max-width: 768px) {}
            @media (max-width: 576px) {
              max-width: 10rem;
            }
          }
				}
        .card-body{
          min-height: unset;
          .prod-title{
            min-height: unset;
          }
        }
			}
		}
  }
</style>
