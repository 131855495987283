<template>
  <div class="preview-data-block d-flex seminar-followed bg-white position-relative" v-if="detail" id="preview-topic">
    <div class="preview-image position-relative">
      <img v-if="!detail.is_followed" src="~/assets/icons/library/white-circle-plus.svg" title="Theo dõi" class="follow-icon position-absolute pointer btn-follow" alt="" @click="handleFollow" />
      <img v-else src="~/assets/icons/library/black-circle-plus.svg" title="Bỏ theo dõi" class="follow-icon position-absolute pointer btn-unfollow" alt="" @click="handleUnFollow"/>
      <!-- <ImageCustom class="card-img-preview" :src="detail.avatar" alt="Card image cap" type="lesson" /> -->
      <ImageNuxt :src="detail.avatar || 'lesson'" class="card-img-preview" alt="Card image cap" preset="topic" />
    </div>
    <div class="preview-content">
      <p class="content-title font-weight-bold limit-line-3">{{detail.name}}</p>
      <div class="content-users d-flex align-items-center flex-nowrap text-nowrap limit-line-1">
        <div class="user d-flex align-items-center color-slightgrey2" v-for="(teacher, ind) in detail.teachers" :key="ind">
          <!-- <ImageCustom :src="teacher.avatar" class="user-avatar rounded-circle" width="24" height="24" alt="avatar" type="avatar" /> -->
          <ImageNuxt :src="teacher.avatar || 'avatar'" class="user-avatar rounded-circle" alt="avatar" preset="topic" width="24" height="24" />
          {{ teacher.name }}
        </div>
      </div>
      <div class="content-video-counter d-flex align-items-center color-slightgrey2">
        <img src="~/assets/icons/library/play-btn-icon.svg" alt="" />
        {{ detail.number_video }} video
      </div>
      <div class="content-video-live-stream-counter d-flex align-items-center color-slightgrey2" v-if="detail.number_livestream">
        <img src="~/assets/icons/library/live-stream-icon.svg" alt="" />
        {{ detail.number_livestream }} buổi Livestream
      </div>
    </div>
    <div class="group-actions position-absolute" v-if="1 === 2">
      <button v-if="!detail.is_joined" type="button" class="btn btn-fk join-btn bg-color-darkblue" @click="handleJoinGroup">Tham gia học</button>
      <button v-else type="button" class="btn btn-fk join-btn bg-color-semanticsc" @click="handleJoinClass">Vào học</button>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
const followResource = new Resource('library/topic/follow')
const unFollowResource = new Resource('library/topic/unFollow')
const checkSubResource = new Resource('price/sub/user')
export default {
  props: {
    detail: {
      type: Object,
      default: null,
      required: true
    }
  },
  async mounted () {
    const params = {
      class_id: this.detail.class_id
    }
    const result = await checkSubResource.list(params).catch(() => {})
    this.$nextTick(() => {
      if (result && result.data && !result.data.is_expired) {
        document.querySelector('.form-check-sub').remove()
      }
      const element = document.querySelector('#preview-topic').offsetHeight
      if (document.querySelector('.form-check-sub')) {
        document.querySelector('.form-check-sub').style.height = element + 'px'
      }
    })
  },
  methods: {
    handleJoinClass () {},
    handleJoinGroup () {
      this.$emit('joinLearn')
    },
    handleFollow () {
      followResource.store({ topic_id: this.detail.id })
        .then((res) => {
          this.$emit('followed', 1)
        })
        .catch(() => {})
    },
    handleUnFollow () {
      unFollowResource.store({ topic_id: this.detail.id })
        .then((res) => {
          this.$emit('followed', 0)
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  .preview-data-block {
    border-radius: .3rem;
    padding: 1rem;
    gap: 1.5rem;
    @media (max-width: 1366px) {
      padding: .75rem;
      gap: 1rem;
    }
    @media (max-width: 1200px) {}
    @media (max-width: 992px) {}
    @media (max-width: 768px) {}
    @media (max-width: 576px) {
      padding: .75rem;
      gap: .5rem;
    }
    .preview-image {
      .follow-icon {
        z-index: 3;
        top: .75rem;
        right: .75rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          top: .5rem;
          right: .5rem;
        }
      }
      &:before {
        content: '';
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0) 100%);
        border-radius: .3rem .3rem 0px 0px;
        z-index: 2;
        height: 3.4rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          height: 2rem;
        }
      }
    }
    .card-img-preview {
      object-fit: cover;
      border-radius: .3rem;
      aspect-ratio: 208 / 136;
      width: 12.5rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
      }
    }
    .content-title {
      line-height: 1.2;
      margin-bottom: .3rem;
      gap: .5rem;
      font-size: 1.5rem;
      @media (max-width: 1366px) {
        font-size: 1.2rem;
      }
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        font-size: 1.2rem;
        -webkit-line-clamp: 1;
      }
    }
    .content-users {
      margin-bottom: .4rem;
      gap: .95rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        gap: .5rem;
      }
      .user {
        gap: .4rem;
        font-size: .95rem;
        line-height: 1.2;
        color: #777777;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
      }
    }
    .content-video-live-stream-counter,
    .content-video-counter {
      font-size: .95rem;
      line-height: 1.2;
      margin-bottom: .3rem;
      gap: .3rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {}
    }
    .content-video-live-stream-counter {
      margin-bottom: 0;
    }
    .group-actions {
      bottom: 1rem;
      right: 1rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {}
    }
    .btn-fk {
      border-radius: 1.25rem;
      color: #ffffff;
      font-weight: 500;
      font-size: 1rem;
      padding: .5rem .75rem;
      width: max-content;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {}
    }
  }
</style>
