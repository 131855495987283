<template>
  <div class="group-related-container bg-white" v-if="group">
    <div class="d-flex pt-2">
      <img src="~/assets/icons/library/private.svg" width="32" height="32" alt="" class="group-facebook">
      <div class="group-title font-size-16 fw-500 text-dark">
        <p class="mb-2 limit-line-3">{{group.name}}</p>
        <p class="class-private text-light-grey fw-400"><img src="~/assets/icons/library/group.svg" width="16" alt="" class="group-private"> Nhóm riêng tư</p>
        <div class="position-relative">
          <template v-for="(avatar, ind) in group.user_avatar">
            <ImageCustom :key="ind" v-if="ind < 4" type="avatar" :src="avatar" height="30" class="position-absolute avatar_member rounded-circle" :class="`avatar_member_${ind + 1}`" />
            <img :key="ind" v-else src="~/assets/icons/library/avt_5.svg" height="30" class="position-absolute avatar_member rounded-circle avatar_member_5">
          </template>
        </div>
      </div>
    </div>
    <ul class="descript-group text-justify">
      <div class="d-flex align-items-start" v-html="group.description"></div>
    </ul>
    <div>
      <button v-if="!group.is_joined" type="button" class="join-class w-100 dark-blue-color mx-1 border-0 fw-700 text-white font-size-18 mb-0" @click="handleJoinGroup">Tham gia học nhóm</button>
      <button v-else type="button" class="join-class w-100 bg-color-semanticsc mx-1 border-0 fw-700 text-white font-size-18 mb-0" @click="handleJoinClass">Đã tham gia nhóm</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
      default: null
    }
  },
  methods: {
    handleJoinClass () {},
    handleJoinGroup () {
      this.$emit('joinGroup')
    }
  }
}
</script>

<style lang="scss" scoped>
  .group-related-container {
    border-radius: 8px;
    padding: 20px;
    width: 420px;
    .group-facebook {
      margin-left: 13px;
      margin-right: 20px;
    }
    .group-title {
      line-height: 20px;
    }
    .class-private {
      line-height: 19px;
      margin-bottom: 10px;
      font-weight: 400;
    }
    .group-private {
      margin-right: 6px;
    }
    .retangle-ic {
      margin-right: 9px;
    }
    .avatar_member{
      border: 3px solid #ffffff;
    }
    .avatar_member_1 {
      z-index: 9;
    }
    .avatar_member_2 {
      z-index: 8;
      left: 20px;
    }
    .avatar_member_3 {
      z-index: 7;
      left: 40px;
    }
    .avatar_member_4 {
      z-index: 6;
      left: 60px;
    }
    .avatar_member_5 {
      z-index: 5;
      left: 80px;
    }
    .descript-group {
      margin-top: 54px;
      padding-right: 21px;
    }
    .join-class {
      border-radius: 26px;
      padding: 12px;
      margin-top: 10px;
      margin-bottom: 7px;
    }
    button.join-class > p {
      line-height: 22px;
    }
  }
</style>
<style lang="scss">
  .group-related-container{
    .descript-group{
      ul{
        padding:revert;
        padding-left: 20px;
        list-style: square;
        li{
          &::marker{
            transform: rotate(45deg);
          }
        }
      }
    }
  }
</style>
